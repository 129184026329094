import React from 'react';
import { Box, Image } from 'grommet';
import { navigate } from 'gatsby'
import { Button } from '@appkit4/react-components/button';
import SidebarCDIR from '../../components/SidebarCDIR';

export default function PersonaCDIR() {
  const onClickButton_CDIRIncident = () => {
    navigate ('./cdir-incident-response')
  }
  const onClickButton_CDIRExplore = () => {
    navigate ('./cdir-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'100vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarCDIR />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/3Q5W4sxop3d9Zsdpqw96QU/ff80bc2e0207b9086db32449156cf583/dis-pe-cdir-my-learning2.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} useMap="#discover-new" />
            <Button style={{width: '80px', position: 'absolute', top: '8px', left: '32%'}} kind='text' onClick={onClickButton_CDIRExplore} ><div className='beacon'></div>&nbsp; &nbsp;</Button>
            <Button style={{width: '100px', position: 'absolute', top: '25vw', left: '9%'}} kind='text' onClick={onClickButton_CDIRIncident} ><div className='beacon'></div>&nbsp; &nbsp; </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}